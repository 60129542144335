import * as CONS from '@/constants';

export default {
  name: 'qr-box',
  components: {},
  props: {
    size: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },
  data () {
    return {
      CONS,
    };
  },
  computed: {
    computedSize () {
      if (typeof this.size === 'number' || (typeof this.size === 'string' && /^\d+$/.test(this.size))) {
        return `${this.size}px`;
      }

      return this.size;
    },
  },
  methods: {},
};
