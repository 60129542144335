import * as CONS from '@/constants';

export default {
  name: 'app-store-button',
  components: {},
  props: { isAppStore: Boolean, isGooglePlay: Boolean },
  data () {
    return {
      CONS,
      stores: {
        appStore: {
          name: 'App Store',
          icon: require('@/assets/icons/icon_apple_white.svg'),
          width: 13.532,
          height: 16.589,
          pcLink: CONS._APP_DOWNLOAD_LINK_PC_APP_STORE,
          mobileLink: CONS._APP_DOWNLOAD_LINK_MOBILE,
        },
        googlePlay: {
          name: 'Google Play',
          icon: require('@/assets/icons/icon_google_play_white.svg'),
          width: 14,
          height: 14.934,
          pcLink: CONS._APP_DOWNLOAD_LINK_PC_GOOGLE_PLAY,
          mobileLink: CONS._APP_DOWNLOAD_LINK_MOBILE,
        },
      },
    };
  },
  mounted () {
    if (this.isAppStore && this.isGooglePlay) {
      throw new Error('error');
    }
    if (!this.isAppStore && !this.isGooglePlay) {
      throw new Error('error');
    }
  },
  computed: {
    store () {
      if (this.isAppStore) {
        return this.stores.appStore;
      }
      if (this.isGooglePlay) {
        return this.stores.googlePlay;
      }
      return {};
    },
  },
  methods: {},
};
