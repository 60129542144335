import * as CONS from '@/constants';

export default {
  name: 'download-text',
  components: {},
  props: {
    noSlash: {
      type: Boolean,
      default: false,
      required: false,
    },
    mobileIcon: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data () {
    return {
      CONS,
    };
  },
  computed: {},
  methods: {},
};
